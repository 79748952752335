<template>
  <div
    class="modal fade"
    tabindex="-10"
    id="dettaglio_attivita_sportiva"
    l-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Attività sportiva - {{ nominativo_persona }}
          </h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body text-start">
          <div class="card-body p-6">
            <div class="ps-5 pe-5 pb-4" v-if="!error">
              <div class="mx-auto my-auto text-center" v-if="loadingDetail">
                <div class="spinner-border text-gray-600" role="status"></div>
                <span class="text-gray-600 ps-3 fs-2">Loading</span>
              </div>
              <div
                v-else-if="
                  attivita_sportiva_list == undefined ||
                  attivita_sportiva_list == null ||
                  attivita_sportiva_list == ''
                "
                class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
              >
                <span class="text-gray-700 ps-3 fs-4">
                  Nessuna attività sportiva presente.
                </span>
              </div>
              <div v-else class="table-responsive card shadow">
                <table class="table table-striped gy-3 gs-7 align-middle">
                  <thead>
                    <tr
                      class="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200"
                    >
                      <th scope="col">Periodo</th>
                      <th scope="col">Nome torneo</th>
                      <th scope="col">Descrizione</th>
                      <th scope="col" v-if="includeTorneiTpra">Giocato</th>
                      <th scope="col" v-if="includeTorneiTpra && 1 == 2">
                        Torneo a squadre
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-for="(torneo, index) in attivita_sportiva_list"
                      :key="index"
                    >
                      <tr>
                        <td>
                          {{ torneo.torneoDa }}
                          <br />
                          {{ torneo.torneoA }}
                        </td>
                        <td class="tab-long">
                          <span v-if="torneo.nomeTorneo">
                            {{ torneo.nomeTorneo }}</span
                          ><span v-else> - </span>
                        </td>
                        <td class="tab-long">
                          <span v-if="torneo.descrizioneTorneo">
                            {{ torneo.descrizioneTorneo }}</span
                          ><span v-else> - </span>
                        </td>
                        <td class="" v-if="includeTorneiTpra">
                          <span
                            class="text-success"
                            v-if="torneo.giocato === true"
                            >SI</span
                          >
                          <span
                            class="text-danger"
                            v-else-if="torneo.giocato === false"
                            >NO</span
                          >
                          <span class="text-danger" v-else>-</span>
                        </td>
                        <td class="" v-if="includeTorneiTpra && 1 == 2">
                          <span
                            class="text-success"
                            v-if="torneo.torneo_a_squadre === true"
                            >SI</span
                          >
                          <span
                            class="text-danger"
                            v-else-if="torneo.torneo_a_squadre === false"
                            >NO</span
                          >
                          <span class="text-danger" v-else>-</span>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <span
            type="button"
            class="me-3 badge bg-light-danger rounded text-danger fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            Chiudi
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "TableCorsoAggSnm",
  props: {
    attivita_sportiva_list: {
      type: Array,
      required: true,
    },
    nominativo_persona: {
      type: String,
      required: true,
    },
    loadingDetail: {
      type: Boolean,
      required: true,
    },
    error: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    return {};
  },
  computed: {
    includeTorneiTpra() {
      return this.attivita_sportiva_list.some(
        (attivita) => attivita.tipologia == "TPRA"
      );
    },
  },
});
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
