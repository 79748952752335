import services from "../axios/dbManag";

export const addRanking = (ranking) => {
  return services.dbManag
    .post("/tesseramento/ranking/add", {
      ...ranking,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const editRanking = (ranking) => {
  return services.dbManag
    .post("/tesseramento/ranking/edit", {
      ...ranking,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const deleteRanking = (id) => {
  return services.dbManag
    .post("/tesseramento/ranking/del", {
      id: id,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const detailRanking = (id) => {
  return services.dbManag
    .post("/tesseramento/ranking/view", {
      id,
    })
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return false;
    });
};

export const viewRankList = (id_Persona, id_disciplina) => {
  return services.dbManag
    .post("/tesseramento/ranking/persona/list", {
      id_Persona,
      id_disciplina,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
