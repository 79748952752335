<template>
  <div class="card shadow">
    <div class="pt-4 pb-4 pe-4 ps-4" @keyup.enter="$emit('getTesserati')">
      <div class="row">
        <div class="col-sm-3 mb-4">
          <input
            type="text"
            class="form-control"
            placeholder="Cognome"
            aria-label=""
            :value="cognome"
            @input="setCognomeTesseratoEnte"
          />
        </div>
        <div class="col-sm-3 mb-4">
          <SelectInput
            :options="certificato_medico"
            name="certificato_medico"
            placeholder="Certificato Medico"
            :value="certificato"
            @changeSelect="setCertificato($event)"
          />
        </div>
        <div class="col-sm-3 mb-4">
          <Datepicker
            v-model="inizioTesseramento"
            yearPicker
            placeholder="Inizio Tesseramento"
            :format="format"
            :monthChangeOnScroll="false"
            :enableTimePicker="false"
            inputClassName="h-28"
            @update:model-value="setInizioTesseramento"
            autoApply
          />
        </div>
        <div class="col-sm-3 mb-4">
          <Datepicker
            v-model="fineTesseramento"
            placeholder="Fine Tesseramento"
            :format="format"
            :monthChangeOnScroll="false"
            :enableTimePicker="false"
            inputClassName="h-28"
            @update:model-value="setFineTesseramento"
            autoApply
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3 filter">
          <input
            type="text"
            class="form-control"
            placeholder="Codice tessera"
            @input="setCodiceTessera"
            aria-label=""
            v-model="codiceTessera"
          />
        </div>
        <div class="col-sm-3 filter">
          <SelectInput
            :options="tesseramento_tipo_tessere"
            name="tesseramento_tipo_tessere"
            placeholder="Tipo"
            :value="id_tipo_tessera"
            @changeSelect="
              setTipoTessera($event);
              getCategoriaTes(id_tipo_tessera);
              setCategoriaTessera(null);
              spliceCategoria();
            "
          />
        </div>
        <div class="col-sm-3 filter">
          <SelectInput
            :options="tesseramento_tipologie"
            name="categoria"
            placeholder="Categoria"
            :value="id_categoria"
            @changeSelect="setCategoriaTessera($event)"
            :disabled="!id_tipo_tessera"
          />
        </div>
        <div class="col-sm-3 filter">
          <SelectInput
            :options="discipline"
            name="discipline"
            placeholder="Settore"
            :value="id_disciplinaTesseratoEnte"
            @changeSelect="setSettore($event)"
          />
        </div>
        <div class="text-center mt-5">
          <button
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600 ms-4"
            @click="$emit('getTesserati')"
            :disabled="!loaded"
          >
            Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
          </button>
          <span
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600 ms-4"
            @click="
              $emit('resetFilters');
              spliceCategoria();
            "
          >
            <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue3-date-time-picker/dist/main.css";
import { defineComponent, computed, getCurrentInstance, ref, watch } from "vue";
import Datepicker from "vue3-date-time-picker";
import { format } from "@/composables/formatDate";
import { useStore } from "vuex";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

export default defineComponent({
  name: "filtri-tesserati-una-societa",
  components: {
    SelectInput,
    Datepicker,
  },
  emits: ["getTesserati", "resetFilters"],
  props: {
    ieta: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    // prendo i filtri da store
    const discipline = computed(() =>
      store.getters.getStateFromName("discipline")
    );
    const tesseramento_tipo_tessere = computed(() =>
      store.getters.getStateFromName("tesseramento_tipo_tessere")
    );
    const tesseramento_tipologie = computed(() =>
      store.getters.getStateFromName("tesseramento_tipologie")
    );
    // const keys = ref("ttt|d|tti");
    const keys = ref("d|ttt");
    // verifico se i filtri sono vuoti, se si li prendo da api, passando le keys qui sopra e il link all'api
    if (!discipline.value || !tesseramento_tipo_tessere.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const stagione = computed(() => store.getters.stagioneSelected);
    // quando cambio stagione prendo i tesserati, passando l'evento al componente "TesseratiUnaieta"
    watch(stagione, () => {
      emit("getTesserati");
    });

    // quando cambio i filtri, cambio i corrispettivi elementi nello store
    const setCognomeTesseratoEnte = (e) => {
      const string = e.target.value;
      store.commit("setCognomeTesseratoEnte", string);
    };
    const setCodiceTessera = (e) => {
      const codice = e.target.value;
      store.commit("setCodiceTesseraTesseratoEnte", codice);
    };
    const setInizioTesseramento = (data) => {
      store.commit("setInizioTesseramentoTesseratoEnte", data);
    };
    const setFineTesseramento = (data) => {
      store.commit("setFineTesseramentoTesseratoEnte", data);
    };
    const setCertificato = (e) => {
      store.commit("setCertificatoTesseratoEnte", parseInt(e));
    };
    const setTipoTessera = (event) => {
      store.commit("setTipoTesseraTesseratoEnte", event);
    };

    const setCategoriaTessera = (event) => {
      store.commit("setCategoriaTesseraTesseratoEnte", event);
    };

    const getCategoriaTes = (id) => {
      store.dispatch("setStoreData", {
        keys: { id_tipo_tessera: id },
        apiLink: globalApi.TESSERAMENTO_TIPOLOGIE,
      });
    };

    const id_categoria = computed(
      () => store.getters.id_categoriaTesseratoEnte
    );

    const spliceCategoria = () => {
      store.commit("spliceArrayFromName", "tesseramento_tipologie");
      id_categoria.value = null;
    };

    const id_disciplinaTesseratoEnte = computed(
      () => store.getters.id_disciplinaTesseratoEnte
    );

    const setSettore = (event) => {
      store.commit("setSettoreTesseratoEnte", event);
    };

    const certificato_medico = ref([
      {
        id: 1,
        label: "In Scadenza",
      },
      {
        id: 2,
        label: "Scaduto",
      },
    ]);
    return {
      setSettore,
      setTipoTessera,
      setCategoriaTessera,
      setCertificato,
      setCognomeTesseratoEnte,
      setCodiceTessera,
      setInizioTesseramento,
      setFineTesseramento,
      certificato_medico,
      tesseramento_tipo_tessere,
      tesseramento_tipologie,
      discipline,
      stagione,
      // prendo i valori qui sotto in sola lettura.
      // cosi in caso di reset filtri, si svuotano tutti i campi
      cognome: computed(() => store.getters.cognomeTesseratoEnte),
      codiceTessera: computed(() => store.getters.codiceTesseraTesseratoEnte),
      inizioTesseramento: computed(
        () => store.getters.inizioTesseramentoTesseratoEnte
      ),
      fineTesseramento: computed(
        () => store.getters.fineTesseramentoTesseratoEnte
      ),
      certificato: computed(() => store.getters.certificatoTesseratoEnte),
      id_categoria,
      id_tipo_tessera: computed(
        () => store.getters.id_tipo_tesseraTesseratoEnte
      ),
      id_disciplinaTesseratoEnte,
      getCategoriaTes,
      spliceCategoria,
      format,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedtesserati_list_ente")
      ),
    };
  },
});
</script>

<style></style>
