<template>
  <div>
    <FiltriTesseratiUnaSocieta
      @getTesserati="searchTesseratoEnteList"
      @resetFilters="resetFilters"
      :societa="ente"
    />
    <TableTesseratiUnaSocieta
      @getTesserati="getTesserati"
      @resetFilters="resetFilters"
      :societa="ente"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import FiltriTesseratiUnaSocieta from "@/components/components-fit/enti-aggregati/tesserati-unasocieta/FiltriTesseratiUnaSocieta.vue";
import TableTesseratiUnaSocieta from "@/components/components-fit/enti-aggregati/tesserati-unasocieta/TableTesseratiUnaSocieta.vue";
import { useStore } from "vuex";
import { trimInput } from "@/composables/trimInput";
export default defineComponent({
  name: "tesserati-unaente",
  props: {
    ente: {
      type: Object,
      required: true,
    },
  },
  components: {
    FiltriTesseratiUnaSocieta,
    TableTesseratiUnaSocieta,
  },
  setup(props) {
    // inclusioni store e global api
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    // stagione da store
    const stagione = computed(() => store.getters.stagioneSelected);
    // filtri da store (store>modules>FiltriTesseratiSocieta.js)
    const cognome = computed(() => store.getters.cognomeTesseratoEnte);
    const codiceTessera = computed(
      () => store.getters.codiceTesseraTesseratoEnte
    );
    const inizioTesseramento = computed(
      () => store.getters.inizioTesseramentoTesseratoEnte
    );
    const fineTesseramento = computed(
      () => store.getters.fineTesseramentoTesseratoEnte
    );
    const certificato = computed(() => store.getters.certificatoTesseratoEnte);
    const id_tipo_tessera = computed(
      () => store.getters.id_tipo_tesseraTesseratoEnte
    );
    const id_categoria = computed(
      () => store.getters.id_categoriaTesseratoEnte
    );
    const id_disciplina = computed(
      () => store.getters.id_disciplinaTesseratoEnte
    );
    const id_qualifica = computed(
      () => store.getters.id_qualificaTesseratoEnte
    );
    const rowsToSkip = computed(() => store.getters.rowsToSkipTesseratoEnte);
    const fetchRows = computed(() => store.getters.fetchRowsTesseratoEnte);
    const sortColumn = computed(() => store.getters.sortColumnTesseratoEnte);
    const sortOrder = computed(() => store.getters.sortOrderTesseratoEnte);

    const getTesserati = () => {
      // attivato tramite evento @getTesserati
      // prendo lista da store dinamico. Passo keys (i parametri in input)
      // apiLink(link all'api), itemName(nome dell'array)
      store.dispatch("setStoreListData", {
        keys: {
          id_societa: props.ente.id,
          stagione: stagione.value,
          nome: null,
          cognome: trimInput(cognome.value),
          certificato: certificato.value,
          id_tipo_tessera: id_tipo_tessera.value,
          id_categoria: id_categoria.value,
          id_qualifica: id_qualifica.value,
          id_disciplina: id_disciplina.value,
          id_categoria_eta: null,
          cod_tessera: trimInput(codiceTessera.value),
          data_emissione_da: inizioTesseramento.value,
          data_emissione_a: fineTesseramento.value,
          id_comitato: null,
          id_regione: null,
          id_provincia: null,
          id_comune: null,
          tessere_estive: null,
          da_approvare: null,
          stampate: null,
          stampate_dal: null,
          stampate_al: null,
          rowsToSkip: rowsToSkip.value,
          fetchRows: fetchRows.value,
          sortColumn: sortColumn.value,
          sortOrder: sortOrder.value,
        },
        apiLink: globalApi.TESSERATI_SOCIETA_LIST,
        itemName: "tesserati_list_ente",
        version: 2,
      });
    };
    // reset filtri. viene attivato tramite evento @resetFilters
    const resetFilters = () => {
      store.commit("resetFiltersTesseratoEnte");
      store.commit("spliceArrayFromName", "tesseramento_tipologie");
      getTesserati();
    };
    const loaded = computed(() =>
      store.getters.getStateFromName("loadedtesserati_list_ente")
    );
    const searchTesseratoEnteList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipTesseratoEnte");
      getTesserati();
    };

    getTesserati();
    onMounted(() => {
      setCurrentPageBreadcrumbs("Tesserati Societa", [
        "Lista Societa",
        "Dettaglio Societa",
      ]);
    });

    return {
      getTesserati,
      resetFilters,
      searchTesseratoEnteList,
    };
  },
});
</script>
