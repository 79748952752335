<template>
  <div class="modal fade" tabindex="-10" id="modal_edit_tesseramento_una_soc">
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        class="form"
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ onlyView ? "Visualizza" : "Modifica" }} tesseramento
            </h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0 !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>
          <div class="modal-body">
            <div class="card-body p-6">
              <div
                class="mx-auto my-auto text-center py-5 my-5"
                v-if="isLoadingDetail"
              >
                <div class="spinner-border text-gray-600" role="status"></div>
                <span class="text-gray-600 ps-3 fs-2">Loading</span>
              </div>
              <div
                class="alert alert-danger"
                role="alert"
                v-show="alertCampiObb"
              >
                Compila i campi obbligatori
              </div>
              <div
                class="ps-5 pe-5"
                v-if="!isLoadingDetail && tesserato.id_persona != undefined"
              >
                <div class="row pb-4">
                  <div class="col-md-3">
                    <label class="fw-bold">Stagione</label>
                    <select class="form-select" aria-label="" disabled>
                      <option selected>{{ tesserato.stagione }}</option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label class="fw-bold">Tesserato</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      aria-label=""
                      v-model="tesserato.nominativo_completo"
                      disabled
                    />
                    <Field
                      name="id"
                      type="hidden"
                      class="form-control"
                      v-model="tesserato.id"
                    />
                  </div>
                </div>
                <div class="row pb-4">
                  <div class="col-md-3">
                    <label class="fw-bold">Tipo tessera</label>
                    <select class="form-select" aria-label="" disabled>
                      <option selected>{{ tesserato.tipo_tessera }}</option>
                    </select>
                    <Field
                      name="tipo_tessera"
                      type="hidden"
                      class="form-control"
                      v-model="tesserato.tipo_tessera"
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="fw-bold">Data emissione</label>
                    <div>
                      <button
                        @click.prevent="
                          isOpenDatepicker = !isOpenDatepicker;
                          openDatepicker(isOpenDatepicker);
                        "
                        class="bugSolver"
                      ></button>
                      <Datepicker
                        v-model="tesserato.data_emissione"
                        name="data_emissione"
                        :maxDate="tesserato.data_scadenza"
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                        @update:model-value="
                          $event
                            ? (tesserato.data_emissione =
                                fromUTCtoISODate($event))
                            : ''
                        "
                        :disabled="onlyView"
                      />
                      <Field
                        name="data_Emissione"
                        type="hidden"
                        class="form-control"
                        v-model="tesserato.data_emissione"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="data_Emissione"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <label class="fw-bold">Data scadenza</label>
                    <Datepicker
                      modelType="yyyy/mm/dd"
                      v-model="tesserato.data_scadenza"
                      placeholder=""
                      :format="format"
                      :monthChangeOnScroll="false"
                      :enableTimePicker="false"
                      inputClassName="h-28"
                      autoApply
                      @update:model-value="
                        $event
                          ? (tesserato.data_scadenza = fromUTCtoISODate($event))
                          : ''
                      "
                      disabled
                    />
                    <Field
                      name="data_Scadenza"
                      type="hidden"
                      class="form-control"
                      v-model="tesserato.data_scadenza"
                    />
                    <ErrorMessage
                      class="help-message"
                      style="color: red"
                      name="data_Scadenza"
                    />
                  </div>
                </div>
                <div class="row pb-4" v-if="tesserato.tipo_tessera != 'Socio'">
                  <div class="col-md-3">
                    <label class="fw-bold">Categoria</label>
                    <select class="form-select" aria-label="" disabled>
                      <option selected>
                        {{ tesserato.categoria.split("/")[0] }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <label class="fw-bold">Classe</label>
                    <select class="form-select" aria-label="" disabled>
                      <option selected>
                        {{ tesserato.categoria.split("/")[1] }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-3">
                    <label class="fw-bold">Settore</label>
                    <select class="form-select" aria-label="" disabled>
                      <option selected>
                        {{ tesserato.categoria.split("/")[2] }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <label class="fw-bold">Categoria d'età</label>
                    <select class="form-select" aria-label="" disabled>
                      <option selected>
                        {{ tesserato.categoria.split("/")[3] }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row pb-4" v-if="tesserato.tipo_tessera != 'Socio'">
                  <div class="col-md-4">
                    <label class="fw-bold">Certificato medico</label>
                    <input
                      class="form-check-input mt-6"
                      type="checkbox"
                      name="flexcheckboxDefault"
                      :checked="tesserato.data_certificato != ''"
                      @click="tesserato.data_certificato = ''"
                      :disabled="onlyView"
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="fw-bold">Data scadenza</label>
                    <Datepicker
                      v-model="tesserato.data_certificato"
                      placeholder=""
                      :format="format"
                      :monthChangeOnScroll="false"
                      :enableTimePicker="false"
                      inputClassName="h-28"
                      autoApply
                      @update:model-value="
                        $event
                          ? (tesserato.data_certificato =
                              fromUTCtoISODate($event))
                          : ''
                      "
                      :disabled="onlyView"
                    />
                    <ErrorMessage
                      class="help-message"
                      style="color: red"
                      name="data_Certificato"
                    />
                  </div>
                  <Field
                    name="data_Certificato"
                    type="hidden"
                    class="form-control"
                    v-model="tesserato.data_certificato"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModalEditTes"
            >
              Chiudi
            </button>
            <button
              v-if="!onlyView"
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
            >
              Salva modifiche
            </button>
          </div>
        </div>
        <Field
          name="obbligo_certificato"
          type="hidden"
          v-model="obbligo_certificato"
          class="form-control"
        />
      </Form>
    </div>
  </div>
</template>

<script>
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import { defineComponent, ref, watch } from "vue";
import {
  viewTesserato,
  editTesserato,
} from "@/requests/tesseratoUnaSocRequests";

import { Form, Field, ErrorMessage } from "vee-validate";
import { format, fromUTCtoISODate } from "@/composables/formatDate";
import { alertFailed, alertSuccess } from "@/composables/swAlert";
import * as yup from "yup";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default defineComponent({
  name: "editTesseratoUnaSocieta",
  emits: ["getTesserati"],
  props: {
    id_tesserato: {
      type: Number,
      required: true,
    },
    onlyView: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isLoadingDetail = ref(false);
    // richiesta tesserato
    const alertCampiObb = ref(false);
    const tesserato = ref({});
    const obbligo_certificato = ref(false);
    const setTesserato = async (id) => {
      isLoadingDetail.value = true;
      tesserato.value.id = id;
      await viewTesserato(id).then((res) => {
        isLoadingDetail.value = false;
        if (res) {
          tesserato.value = { ...tesserato.value, ...res };
          tesserato.value.valida === 1
            ? (tesserato.value.valida = true)
            : (tesserato.value.valida = false);
          obbligo_certificato.value = res.obbligo_certificato;
        } else {
          alertFailed(
            "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        }
      });
      // getTipologieScuole(scuola.value.id_disciplina);
    };
    // watch(
    //   () => props.id_tesserato,
    //   () => {
    //     if (props.id_tesserato) setTesserato(props.id_tesserato);
    //   }
    // );

    const disableButton = ref(false);
    const isLoading = ref(false);

    const onSubmit = (values) => {
      disableButton.value = true;
      isLoading.value = true;
      const responseAdd = ref({});
      responseAdd.value = editTesserato(values);
      responseAdd.value.then((value) => {
        if (value.status == 200) {
          alertSuccess("Tesseramento modificato con successo!");
          alertCampiObb.value = false;
          document.getElementById("closeModalEditTes").click();
          emit("getTesserati");
        } else {
          alertFailed(
            value.data.message
              ? value.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        }
        disableButton.value = false;
        isLoading.value = false;
      });
    };

    const onInvalidSubmit = () => {
      alertCampiObb.value = true;
    };

    const certificato = ref(false);

    watch(
      () => props.id_tesserato,
      async (value) => {
        if (value != null) await setTesserato(value);
      }
    );

    const schema = yup.object().shape({
      obbligo_certificato: yup.boolean(),
      data_Emissione: yup.string().nullable().required("Obbligatorio"),
      data_Certificato: yup
        .string()
        .when(["tipo_tessera", "obbligo_certificato"], {
          is: (tipo_tessera, obbligo_certificato) =>
            tipo_tessera === "Socio" ||
            (tipo_tessera === "Atleta" && !obbligo_certificato),
          then: yup.string().nullable(),
          otherwise: yup.string().required("Obbligatorio").nullable(),
        }),
    });
    return {
      schema,
      tesserato,
      alertCampiObb,
      format,
      onSubmit,
      onInvalidSubmit,
      fromUTCtoISODate,
      disableButton,
      isLoading,
      isLoadingDetail,
      certificato,
      obbligo_certificato,
    };
  },
  components: { Loading, Datepicker, Form, Field, ErrorMessage },
});
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52% !important;
    margin: 9rem !important;
    margin-top: 5rem !important;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 70% !important;
    margin: 3px !important;
  }
}
</style>
