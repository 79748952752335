<template>
  <div
    class="modal fade"
    tabindex="-10"
    id="modal_visualizza_tessera"
    l-bs-keyboard="false"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Visualizza Tessera</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="
              reset += 1;
              $emit('resetTessera');
            "
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body text-start">
          <div class="card-body p-6">
            <QRCode :cod_tessera="cod_tessera" :crypt="crypt" :reset="reset" />
          </div>
        </div>
        <div class="modal-footer">
          <span
            type="button"
            class="me-3 badge bg-light-danger rounded text-danger fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="
              reset += 1;
              $emit('resetTessera');
            "
          >
            Chiudi
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import QRCode from "@/views/QrCodePagina.vue";

export default defineComponent({
  name: "ModalTesseraQR",
  emits: ["resetTessera"],
  props: {
    cod_tessera: {
      type: String,
      required: true,
    },
    crypt: {
      type: String,
      required: true,
    },
  },
  components: {
    QRCode,
  },
  setup() {
    const reset = ref(0);
    return {
      reset,
    };
  },
});
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
