<template>
  <div class="modal fade" tabindex="-10" id="modal_add-tesserato-unasocieta">
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        class="form"
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Nuovo tesseramento</h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>
          <div class="modal-body">
            <div class="card-body p-6">
              <form autocomplete="off">
                <div class="ps-5 pe-5">
                  <div class="row">
                    <button
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#modal_add-anagrafica-persona"
                      class="btn btn-light text-gray-700 fs-6"
                    >
                      <i class="bi bi-plus fs-6 text-gray-700"></i>inserisci
                      nuova anagrafica
                    </button>
                  </div>

                  <hr />
                  <div
                    class="alert alert-danger"
                    role="alert"
                    v-if="alertCampiObb"
                  >
                    Compila i campi obbligatori
                  </div>
                  <div class="row pb-2 ps-3">
                    Anagrafica già presente all'interno del sistema:
                  </div>
                  <div class="row mb-4">
                    <!-- <div class="col-md-3">
                  <label class="fw-bold ">Stagione</label>
                  <select class="form-select" aria-label="" disabled>
                    <option selected>-</option>
                    <option value="1">Si</option>
                    <option value="2">No</option>
                  </select>
                </div> -->
                    <div class="col-sm-6">
                      <label class="fw-bold">Persona*</label>
                      <div class="dp__input_wrap text-gray-600">
                        <input
                          placeholder=""
                          name="nome_tesserato"
                          type="text"
                          class="form-control"
                          autocomplete="off"
                          @input="getTesserati($event.target.value)"
                          v-model="nome_persona"
                        />
                        <Field
                          name="id_persona"
                          type="hidden"
                          v-model="id_persona"
                          class="form-control"
                        /><svg
                          @click="resetField()"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          class="dp__icon dp__clear_icon dp__input_icons"
                        >
                          <path
                            d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                          ></path>
                          <path
                            d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                          ></path>
                        </svg>
                      </div>
                      <ListTesserati
                        :list="tesserati"
                        @hideList="resetTesserati;"
                        @selectedTesserato="
                          setTesserato($event);
                          resetTesserati();
                          resetValori();
                        "
                      ></ListTesserati>
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="id_persona"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold">Tipo tessera*</label>
                      <SelectInput
                        :options="tessereList"
                        name="id_tipo_tessera"
                        placeholder=""
                        :value="id_tipo_tessera"
                        :disabled="id_persona == null"
                        @changeSelect="setTipoTessera($event)"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold">Data emissione*</label>
                      <Datepicker
                        v-model="data_emissione"
                        @update:modelValue="
                          $event
                            ? (data_emissione = fromUTCtoISODate($event))
                            : ''
                        "
                        :minDate="new Date()"
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                        readonly
                      />
                      <Field
                        name="data_emissione"
                        type="hidden"
                        v-model="data_emissione"
                        class="form-control"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="data_emissione"
                      />
                    </div>
                  </div>
                  <!--       <div class="row pb-4">
               <div class="col-md-3">
                  <label class="fw-bold">Data scadenza</label>
                  <Datepicker
                    v-model="data_scadenza"
                    @update:modelValue="
                      $event ? (data_scadenza = fromUTCtoISODate($event)) : ''
                    "
                    placeholder=""
                    :format="format"
                    :monthChangeOnScroll="false"
                    :enableTimePicker="false"
                    inputClassName="h-28"
                    autoApply
                    :disabled="id_persona == null"
                  />
                </div>
              </div> -->
                  <div class="row pb-4" v-if="id_tipo_tessera == 1">
                    <div class="col-md-3">
                      <label class="fw-bold">Categoria*</label>
                      <SelectInput
                        :options="tipologieTessera"
                        name="id_tipologia"
                        placeholder=""
                        :value="id_tipologia"
                        :disabled="
                          id_tipo_tessera == null || id_tipo_tessera == 6
                        "
                        @changeSelect="setTipologiaTessera($event)"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold">Settore*</label>
                      <SelectInput
                        :options="disciplineTessera"
                        name="id_disciplina"
                        placeholder=""
                        :value="id_disciplina"
                        :disabled="id_tipologia == null || id_tipo_tessera == 6"
                        @changeSelect="setDisciplinaTessera($event)"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold">Classe*</label>
                      <input
                        v-if="id_disciplina"
                        v-model="categoria"
                        name="categoria_eta"
                        class="form-control"
                        readonly
                      />
                      <input v-else disabled class="form-control" />
                      <Field
                        type="hidden"
                        name="id_categoria"
                        v-model="id_categoria"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="id_categoria"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold">Categoria d'età*</label>
                      <input
                        v-if="id_disciplina"
                        v-model="categoria_eta"
                        name="categoria_eta"
                        class="form-control"
                        readonly
                      />
                      <input v-else disabled class="form-control" />
                      <Field
                        type="hidden"
                        name="id_cat_eta_p"
                        v-model="id_cat_eta_p"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="id_cat_eta_p"
                      />
                    </div>
                  </div>
                  <div class="row pb-4" v-if="id_tipo_tessera == 1">
                    <div class="col-md-3">
                      <label class="fw-bold"
                        >Certificato medico
                        <span v-if="obbligo_certificato">*</span></label
                      >
                      <input
                        class="form-check-input ms-2 me-4"
                        type="checkbox"
                        name="flexcheckboxDefault"
                        v-model="certificato_medico"
                        :disabled="!id_persona || id_tipo_tessera == 6"
                      />
                      <Field
                        type="hidden"
                        name="certificato_medico"
                        v-model="certificato_medico"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="certificato_medico"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold"
                        >Scadenza certificato<span v-if="obbligo_certificato"
                          >*</span
                        ></label
                      >
                      <Datepicker
                        v-model="data_scadenza_certificato_medico"
                        name="data_scadenza_certificato_medico"
                        :minDate="new Date()"
                        @update:modelValue="
                          $event
                            ? (data_scadenza_certificato_medico =
                                fromUTCtoISODate($event))
                            : ''
                        "
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                        :disabled="id_persona == null || !certificato_medico"
                      />
                      <Field
                        name="data_scadenza_certificato_medico"
                        type="hidden"
                        v-model="data_scadenza_certificato_medico"
                        class="form-control"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="data_scadenza_certificato_medico"
                      />
                    </div>
                  </div>
                  <div
                    class="row pb-4 justify-content-start"
                    v-if="id_tipo_tessera == 1 && (socio || tessera_estiva)"
                  >
                    <hr />
                    <div class="col-md-3" v-if="socio">
                      <label class="fw-bold"> Upgrade da tessera Socio</label>
                      <input
                        class="form-check-input ms-2 me-4"
                        type="checkbox"
                        name="flexcheckboxDefault"
                        v-model="set_upgrade_tessera_socio"
                      />
                      <Field
                        name="set_upgrade_tessera_socio"
                        type="hidden"
                        v-model="set_upgrade_tessera_socio"
                        class="form-control"
                      />
                    </div>
                    <div class="col-md-3" v-if="tessera_estiva">
                      <label class="fw-bold"> Estiva</label>
                      <input
                        class="form-check-input ms-2 me-4"
                        type="checkbox"
                        name="flexcheckboxDefault"
                        v-model="set_tessera_estiva"
                      />
                      <Field
                        name="set_tessera_estiva"
                        type="hidden"
                        v-model="set_tessera_estiva"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <hr v-if="id_persona" />
                  <div class="row" v-if="id_persona">
                    <div class="d-flex">
                      <label class="fw-bold"
                        >Partecipa al "Loyalty Program"</label
                      >
                      <input
                        class="form-check-input ms-2 me-4"
                        type="checkbox"
                        name="loyalty_program"
                        v-model="loyalty_program"
                      />
                      <Field
                        type="hidden"
                        name="loyalty_program"
                        v-model="loyalty_program"
                      />
                    </div>
                  </div>
                  <hr v-if="!privacy" />
                  <div class="row pb-2 ps-3" v-if="!privacy">
                    Trattamento dati personali:
                  </div>
                  <Field type="hidden" name="privacy" v-model="privacy" />
                  <div class="d-block d-md-flex" v-if="!privacy">
                    <div class="d-flex">
                      <div>
                        <label class="fw-bold">Paragrafo 1.A*</label>
                        <input
                          class="form-check-input ms-2 me-5"
                          type="checkbox"
                          name="privacy_A"
                          v-model="privacy_A"
                        />
                        <Field
                          type="hidden"
                          name="privacy_A"
                          v-model="privacy_A"
                        />
                        <br />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="privacy_A"
                        />
                      </div>
                    </div>
                    <div class="d-flex">
                      <label class="fw-bold">Paragrafo 1.B</label>
                      <input
                        class="form-check-input ms-2 me-5"
                        type="checkbox"
                        name="flexcheckboxDefault"
                        v-model="privacy_B"
                      />
                      <Field
                        name="privacy_B"
                        type="hidden"
                        v-model="privacy_B"
                        class="form-control"
                      />
                    </div>
                    <div class="d-flex">
                      <label class="fw-bold">Paragrafo 1.C</label>
                      <input
                        class="form-check-input ms-2 me-5"
                        type="checkbox"
                        name="flexcheckboxDefault"
                        v-model="privacy_C"
                      />
                      <Field
                        name="privacy_C"
                        type="hidden"
                        v-model="privacy_C"
                        class="form-control"
                      />
                    </div>
                    <div class="d-flex">
                      <label class="fw-bold">Paragrafo 1.D</label>
                      <input
                        class="form-check-input ms-2 me-5"
                        type="checkbox"
                        name="flexcheckboxDefault"
                        v-model="privacy_D"
                      />
                      <Field
                        name="privacy_D"
                        type="hidden"
                        v-model="privacy_D"
                        class="form-control"
                      />
                    </div>
                    <div class="d-flex">
                      <label class="fw-bold">Paragrafo 1.E</label>
                      <input
                        class="form-check-input ms-2 me-5"
                        type="checkbox"
                        name="flexcheckboxDefault"
                        v-model="privacy_E"
                      />
                      <Field
                        name="privacy_E"
                        type="hidden"
                        v-model="privacy_E"
                        class="form-control"
                      />
                    </div>
                    <Field
                      name="obbligo_certificato"
                      type="hidden"
                      v-model="obbligo_certificato"
                      class="form-control"
                    />
                    <!-- <div class="col-3 d-flex">
                      <label class="fw-bold"
                        >Paragrafo 1.D</label
                      >
                      <div class="ms-4 mt-1">
                        <TextInput name="privacy3" type="checkbox"  :checked="privacy4" @changeEvent="privacy4 = !privacy4"/>
                      </div>
                    </div> -->
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModalAddTes"
            >
              Chiudi
            </button>
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
            >
              Invia
            </button>
          </div>
        </div></Form
      >
    </div>
  </div>
  <ModalAddPersona
    :fromTesseramento="true"
    @setupTesseramento="setupTesseramento($event)"
  />
</template>

<script>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import Datepicker from "vue3-date-time-picker";
import { format } from "@/composables/formatDate";
import "vue3-date-time-picker/dist/main.css";
import ModalAddPersona from "@/components/components-fit/persone/ModalAddPersona.vue";
import {
  tessereSottoscrivibili,
  addTesseratoSocieta,
} from "@/requests/tesseratoUnaSocRequests";
import ListTesserati from "@/components/components-fit/utility/ListTesserati.vue";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import { fromUTCtoISODate } from "@/composables/formatDate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Form, ErrorMessage, Field } from "vee-validate";
import * as yup from "yup";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  name: "add-tesserato-una-societa",
  components: {
    Loading,
    Datepicker,
    ModalAddPersona,
    ListTesserati,
    SelectInput,
    Form,
    Field,
    ErrorMessage,
  },
  emits: ["getTesserati"],
  props: {
    stagione: {
      type: Number,
      required: true,
    },
    id_societa: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const id_persona = ref(null);
    const nome_persona = ref(null);
    const obbligo_certificato = ref(false);
    const id_tipo_tessera = ref(null);
    const id_tipologia = ref(null);
    const id_disciplina = ref(null);
    const id_categoria = ref(null);
    const id_cat_eta_p = ref(null);
    const categoria = ref(null);
    const categoria_eta = ref(null);
    const data_emissione = ref(fromUTCtoISODate(new Date()));
    //const data_scadenza = ref(null);
    const certificato_medico = ref(false);
    const data_scadenza_certificato_medico = ref(null);
    const tessera_estiva = ref(false);
    const set_tessera_estiva = ref(false);
    const set_upgrade_tessera_socio = ref(false);
    const loyalty_program = ref(false);
    const privacy_A = ref(false);
    const privacy_B = ref(false);
    const privacy_C = ref(false);
    const privacy_D = ref(false);
    const privacy_E = ref(false);
    const sottoscrivibile = ref(0);
    const spostamento_a_cura_CR = ref(0);
    const posseduta = ref(0);
    const tessera_SAT = ref(false);
    const cod_tessera = ref("");
    const pretesseramento = ref(0);

    const getTesserati = (string) => {
      if (string === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getPersoneNomeCognome", string);
    };
    const resetTesserati = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const tessereList = ref({});
    const socio = ref(0);
    const privacy = ref(1);
    const setTesserato = async (event) => {
      id_persona.value = event.value;
      nome_persona.value = event.name;
      obbligo_certificato.value = event.obbligo_certificato;
      await tessereSottoscrivibili(
        props.stagione,
        id_persona.value,
        props.id_societa
      ).then((res) => {
        socio.value = res.socio;
        privacy.value = res.privacy;
        if (res.results.length > 0) tessereList.value = res.results;
      });
    };
    watch(tessereList);

    const resetField = () => {
      alertCampiObb.value = false;
      id_tipo_tessera.value = null;
      id_persona.value = null;
      nome_persona.value = null;
      id_categoria.value = null;
      id_cat_eta_p.value = null;
      categoria.value = null;
      categoria_eta.value = null;
      data_emissione.value = fromUTCtoISODate(new Date());
      //data_scadenza.value = null;
      certificato_medico.value = false;
      data_scadenza_certificato_medico.value = null;
      tessera_estiva.value = false;
      socio.value = 0;
      privacy.value = 1;
      set_tessera_estiva.value = false;
      set_upgrade_tessera_socio.value = false;
      loyalty_program.value = false;
      privacy_A.value = false;
      privacy_B.value = false;
      privacy_C.value = false;
      privacy_D.value = false;
      privacy_E.value = false;
      sottoscrivibile.value = 0;
      pretesseramento.value = 0;
      spostamento_a_cura_CR.value = 0;
      posseduta.value = 0;
      tessera_SAT.value = false;
      cod_tessera.value = "";
      tessereSottoscrivibili.value.splice(
        0,
        tessereSottoscrivibili.value.length
      );
    };

    const tipologieTessera = ref([]);

    const resetValori = () => {
      id_tipo_tessera.value = null;
      tipologieTessera.value.splice(0, tipologieTessera.value.length);
      disciplineTessera.value.splice(0, disciplineTessera.value.length);
      id_tipologia.value = null;
      id_disciplina.value = null;
      id_categoria.value = null;
      id_cat_eta_p.value = null;
      categoria.value = null;
      categoria_eta.value = null;
      certificato_medico.value = false;
      data_scadenza_certificato_medico.value = null;
      tessera_estiva.value = false;
      set_tessera_estiva.value = false;
      set_upgrade_tessera_socio.value = false;
      loyalty_program.value = null;
      privacy_A.value = null;
      privacy_B.value = null;
      privacy_C.value = null;
      privacy_D.value = null;
      privacy_E.value = null;
    };

    const setTipoTessera = (event) => {
      id_tipo_tessera.value = event;
      tipologieTessera.value.splice(0, tipologieTessera.value.length);
      disciplineTessera.value.splice(0, disciplineTessera.value.length);
      id_tipologia.value = null;
      id_disciplina.value = null;
      id_categoria.value = null;
      id_cat_eta_p.value = null;
      categoria.value = null;
      categoria_eta.value = null;
      if (id_tipo_tessera.value == 1) {
        tessereList.value.forEach((element) => {
          if (element.id_tipo_tessera === parseInt(id_tipo_tessera.value)) {
            element.tipologie.forEach((tipologie) => {
              tipologieTessera.value.push(tipologie);
            });
          }
        });
      } else if (id_tipo_tessera.value == 6) {
        tessereList.value.forEach((element) => {
          if (element.id_tipo_tessera === parseInt(id_tipo_tessera.value)) {
            element.tipologie.forEach((tipologie) => {
              tipologieTessera.value.push(tipologie);
            });
          }
        });
        tipologieTessera.value.forEach((element) => {
          element.discipline.forEach((discipline) => {
            disciplineTessera.value.push(discipline);
          });
        });
        sottoscrivibile.value = disciplineTessera.value[0].sottoscrivibile;
        pretesseramento.value = disciplineTessera.value[0].pretesseramento;
        spostamento_a_cura_CR.value =
          disciplineTessera.value[0].spostamento_a_cura_CR;
        posseduta.value = disciplineTessera.value[0].posseduta;
        tessera_SAT.value = disciplineTessera.value[0].tessera_SAT;
        cod_tessera.value = disciplineTessera.value[0].cod_tessera;
        id_tipologia.value = tipologieTessera.value[0].id_tipologia;
        id_disciplina.value = disciplineTessera.value[0].id_disciplina;
        id_categoria.value = disciplineTessera.value[0].id_cat_class;
        id_cat_eta_p.value = disciplineTessera.value[0].id_cat_eta_p;
        categoria.value = null;
        categoria_eta.value = null;
        data_emissione.value = fromUTCtoISODate(new Date());
        //data_scadenza.value = null;
        certificato_medico.value = false;
        data_scadenza_certificato_medico.value = null;
        tessera_estiva.value = false;
        set_tessera_estiva.value = false;
        set_upgrade_tessera_socio.value = false;
      }
    };

    const disciplineTessera = ref([]);
    const setTipologiaTessera = (event) => {
      id_tipologia.value = event;
      id_disciplina.value = null;
      id_categoria.value = null;
      id_cat_eta_p.value = null;
      tessera_estiva.value = false;
      set_tessera_estiva.value = false;
      disciplineTessera.value.splice(0, disciplineTessera.value.length);
      tipologieTessera.value.forEach((element) => {
        if (element.id_tipologia === parseInt(id_tipologia.value)) {
          element.discipline.forEach((discipline) => {
            disciplineTessera.value.push(discipline);
          });
        }
      });
    };

    const dettaglioTessera = ref([]);
    const setDisciplinaTessera = (event) => {
      id_disciplina.value = event;
      dettaglioTessera.value.splice(0, disciplineTessera.value.length);
      disciplineTessera.value.forEach((element) => {
        if (element.id_disciplina == id_disciplina.value) {
          dettaglioTessera.value.push(element);
        }
      });
      id_categoria.value = dettaglioTessera.value[0].id_cat_class;
      id_cat_eta_p.value = dettaglioTessera.value[0].id_cat_eta_p;
      categoria.value = dettaglioTessera.value[0].categoria;
      categoria_eta.value = dettaglioTessera.value[0].categoria_eta;
      tessera_estiva.value = dettaglioTessera.value[0].tessera_estiva;
      sottoscrivibile.value = dettaglioTessera.value[0].sottoscrivibile;
      pretesseramento.value = dettaglioTessera.value[0].pretesseramento;
      spostamento_a_cura_CR.value =
        dettaglioTessera.value[0].spostamento_a_cura_CR;
      posseduta.value = dettaglioTessera.value[0].posseduta;
      tessera_SAT.value = dettaglioTessera.value[0].tessera_SAT;
      cod_tessera.value = dettaglioTessera.value[0].cod_tessera;
    };

    const alertCampiObb = ref(false);
    const schema = yup.object().shape({
      id_persona: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      id_tipo_tessera: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      data_emissione: yup.string().required("Obbligatorio").nullable(),
      id_tipologia: yup.number().when("id_tipo_tessera", {
        is: (id_tipo_tessera) => id_tipo_tessera === 1,
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .required("Obbligatorio")
          .nullable(),
        otherwise: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      id_disciplina: yup.number().when("id_tipo_tessera", {
        is: (id_tipo_tessera) => id_tipo_tessera === 1,
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .required("Obbligatorio")
          .nullable(),
        otherwise: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      id_categoria: yup.number().when("id_tipo_tessera", {
        is: (id_tipo_tessera) => id_tipo_tessera === 1,
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .required("Obbligatorio")
          .nullable(),
        otherwise: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      id_cat_eta_p: yup.number().when("id_tipo_tessera", {
        is: (id_tipo_tessera) => id_tipo_tessera === 1,
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .required("Obbligatorio")
          .nullable(),
        otherwise: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      obbligo_certificato: yup.boolean(),
      certificato_medico: yup
        .boolean()
        .when(["id_tipo_tessera", "obbligo_certificato"], {
          is: (id_tipo_tessera, obbligo_certificato) =>
            id_tipo_tessera === 1 && obbligo_certificato,
          then: yup
            .boolean()
            .oneOf([true], "Obbligatorio")
            .required("Obbligatorio"),
          otherwise: yup.boolean(),
        }),
      data_scadenza_certificato_medico: yup
        .string()
        .when(["id_tipo_tessera", "obbligo_certificato"], {
          is: (id_tipo_tessera, obbligo_certificato) =>
            id_tipo_tessera === 1 && obbligo_certificato,
          then: yup.string().required("Obbligatorio").nullable(),
          otherwise: yup.string().nullable(),
        }),
      privacy_A: yup.boolean().when("privacy", {
        is: (privacy) => privacy === 0,
        then: yup
          .boolean()
          .oneOf([true], "Obbligatorio")
          .required("Obbligatorio")
          .nullable(),
        otherwise: yup.boolean().nullable(),
      }),
    });

    const onInvalidSubmit = (v) => {
      alertCampiObb.value = true;
    };

    const disableButton = ref(false);
    const isLoading = ref(false);

    const onSubmit = (values) => {
      values = {
        anno: props.stagione,
        id_Societa: props.id_societa,
        id_Comitato: 0,
        id_Persona: id_persona.value,
        id_Disciplina: id_disciplina.value,
        cod_Tessera: cod_tessera.value == undefined ? "" : cod_tessera.value,
        data_Emissione: data_emissione.value,
        id_Tipo_Tessera: id_tipo_tessera.value,
        certificato: certificato_medico.value,
        data_Certificato: data_scadenza_certificato_medico.value,
        sat: tessera_SAT.value,
        estiva: set_tessera_estiva.value,
        tessera_Da_Richiesta_Online: false,
        upgrade_Da_Socio: set_upgrade_tessera_socio.value,
        tesseramento_Consiglio_Da_Affiliazione: null,
        id_Tipologia: id_tipologia.value,
        id_Categoria_Classifica: id_categoria.value,
        id_Categoria_Eta: id_cat_eta_p.value,
        privacy1: privacy.value ? null : privacy_A.value,
        privacy2: privacy.value ? null : privacy_B.value,
        privacy3: privacy.value ? null : privacy_C.value,
        privacy4: privacy.value ? null : privacy_D.value,
        privacy5: privacy.value ? null : privacy_E.value,
        loyalty_program: loyalty_program.value,
      };
      alertCampiObb.value = false;
      disableButton.value = true;
      isLoading.value = true;
      if (pretesseramento.value == 1) {
        disableButton.value = false;
        isLoading.value = false;
        Swal.fire({
          html: "Attenzione! E' già presente una richiesta di pretesseramento",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else if (sottoscrivibile.value == 1) {
        const responseAdd = ref({});
        let messaggioResponse;
        responseAdd.value = addTesseratoSocieta(values);
        messaggioResponse = "Il tesseramento è stato registrato con successo";
        responseAdd.value.then((value) => {
          disableButton.value = false;
          isLoading.value = false;
          if (value.status == 200) {
            Swal.fire({
              html: messaggioResponse,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-success",
              },
            });
            document.getElementById("closeModalAddTes").click();
            emit("getTesserati");
            resetField();
          } else {
            const strMsg = ref(
              value.data.message
                ? value.data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
            Swal.fire({
              html: strMsg.value,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          }
        });
      } else if (sottoscrivibile.value == 0) {
        if (spostamento_a_cura_CR.value == 1 && posseduta.value == 1) {
          Swal.fire({
            html: "Attenzione! La tessera è gia posseduta dalla persona selezionata,<br> per effettuare il tesseramento è necessario il trasferimento da parte del CR",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        } else if (posseduta.value == 1) {
          Swal.fire({
            html: "Attenzione! Il tesserato è già in possesso della tessera selezionata.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        } else if (spostamento_a_cura_CR.value == 1) {
          Swal.fire({
            html: "Attenzione! Per sottoscrivere la tesserà è necessario che venga effettuato il trasferimento da parte del CR",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        } else {
          Swal.fire({
            html: "Attenzione! Non è possibile sottoscrivere la tessera",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        }
        disableButton.value = false;
        isLoading.value = false;
      }
    };

    const setupTesseramento = ({ id_pers, nome_completo }) => {
      nome_persona.value = nome_completo;
      id_persona.value = id_pers;
      setTesserato({ name: nome_persona.value, value: id_persona.value });
    };

    return {
      socio,
      privacy,
      id_persona,
      nome_persona,
      id_tipo_tessera,
      id_tipologia,
      id_disciplina,
      id_categoria,
      id_cat_eta_p,
      categoria,
      categoria_eta,
      data_emissione,
      certificato_medico,
      data_scadenza_certificato_medico,
      tessera_estiva,
      set_tessera_estiva,
      set_upgrade_tessera_socio,
      privacy_A,
      privacy_B,
      privacy_C,
      privacy_D,
      privacy_E,
      loyalty_program,
      sottoscrivibile,
      pretesseramento,
      posseduta,
      spostamento_a_cura_CR,
      tessera_SAT,
      cod_tessera,
      setupTesseramento,

      tesserati: computed(() => store.getters.personeSocieta),
      getTesserati,
      resetTesserati,
      setTesserato,
      tessereList,
      tipologieTessera,
      disciplineTessera,

      resetField,
      resetValori,
      setTipoTessera,
      setTipologiaTessera,
      setDisciplinaTessera,
      onSubmit,

      fromUTCtoISODate,
      onInvalidSubmit,
      alertCampiObb,
      schema,
      format,
      disableButton,
      isLoading,
      obbligo_certificato,
    };
  },
};
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
    margin-top: 5rem;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}
</style>
